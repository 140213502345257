<template>
  <img :src="$filters.image(image.path)" :style="animationStyle">
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  props: {
    image: Object,
    animate: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const store = useStore()
    const animation = computed(() => {
      const animations = store.getters['configurator/animations']
      const anim = animations.find(a => a.id === props.image.animation_id)
      return anim
    })
    const animationStyle = computed(() => {
      if (typeof animation.value === 'undefined') {
        return {}
      }
      const style = {
        transition: 'all ' + animation.value.duration + 's linear'
      }
      if (props.animate) {
        return {
          ...style,
          transform: 'translateX(' + animation.value.translate_x + '%) translateY(' + animation.value.translate_y + '%) scale(' + animation.value.scale + ')'
        }
      } else {
        return {
          transition: 'transition: all 5s linear 0s'
        }
      }
    })
    return {
      animationStyle
    }
  }
}
</script>
